<template>
  <div class="pic_detail">
    
    <ele-drawer
      :isVisible="isVisible"
      :drawerTitle="t('1243')"
      :drawerWidth="1325"
      @on-close="$emit('update:isVisible', false)"
    >
      <div class="main_box">
        <div class="main_box_hd">
          <div class="fl">{{ t('1244') }}：</div>
          <div class="sort_box fl">
            <a
              class="sort_a"
              :class="{ curretn_sort_a: currentIndex === parentIndex + 1 }"
              :href="'#' + (parentIndex + 1)"
              v-for="(parentItem, parentIndex) in picList"
              :key="parentIndex"
              @click.prevent="jumpTo(parentIndex + 1)"
              v-show="parentItem.itemArr.length !== 0"
              >{{ parentItem?.itemArr[0]?.metadata?.waypointIndex === -1 ? t('913') : parentIndex + 1 }}</a
            >
          </div>
          <div class="fr btn_group clearfix">
            <el-button
              class="no_bg_btn left_35"
              v-show="downStatus"
              @click="downStatus = false"
              >{{ t('217') }}</el-button
            >
            <el-button
              class="bg_btn confirm_btn left_35"
              v-show="downStatus"
              @click="batchDownload"
              >{{ t('218') }}</el-button
            >
            <div class="check_box fr left_35" v-show="downStatus">
              <label class="check_label" for>{{ t('676') }}</label>
              <el-checkbox
                v-model="allChecked"
                @change="parentAllCheck"
              ></el-checkbox>
            </div>
            <el-button
              class="bg_btn"
              v-show="!downStatus && picList.length > 0"
              @click="downStatus = true"
              >{{ t('1245') }}</el-button
            >
          </div>
        </div>
        <div class="main_box_bd">
          <div
            class="item"
            v-for="(parentItem, parentIndex) in picList"
            :key="parentIndex"
            v-show="parentItem.itemArr.length !== 0"
          >
            <div class="item_hd clearfix">
              <a class="sort_a" :id="parentIndex + 1">{{ parentItem?.itemArr[0]?.metadata?.waypointIndex === -1 ? t('913') : parentIndex + 1 }}</a>
              <div
                class="check_box all_check_box fr"
                v-show="parentItem.itemArr.length > 0 && downStatus"
              >
                <label class="check_label" for>{{ t('676') }}</label>
                <el-checkbox
                  v-model="parentItem.checked"
                  @change="(checked) => childAllCheck(checked, parentIndex)"
                ></el-checkbox>
              </div>
            </div>
            <div class="item_bd">
              <el-checkbox-group
                v-model="parentItem.checkArr"
                @change="(checked) => childCheck(checked, parentIndex)"
              >
                <ul class="pic_ul clearfix">
                  <li
                    class="fl"
                    v-for="(childItem, childIndex) in parentItem.itemArr"
                    :key="childIndex"
                  >
                    <div class="pic_con">
                      <el-image
                        v-if="childItem.metadata.mediaType!=12"
                        :src="childItem.imageUrl + '!t270x150'"
                        @click="
                          clickImage(childIndex, previewSrcList[parentIndex])
                        "
                        lazy
                      ></el-image>
                       <video id="firstVideo" preload="meta"  v-else class="my-video"  @click="clickVideo(childItem)">
                        <source :src="childItem.imageUrl+'#t=0.1'" type="video/mp4">
                      </video>
                    </div>
                    <div class="pic_name">
                      <div class="name_con" :title="childItem.metadata.mediaType!=12?childItem.imageName:childItem.metadata.fileName">
                        {{ childItem.metadata.mediaType!=12?childItem.imageName:childItem.metadata.fileName }}
                      </div>
                      <el-icon
                        :size="18"
                        class="element_icon fr"
                        @click="
                          downloadPic(childItem.imageUrl, childItem.metadata.mediaType!=12?childItem.imageName:childItem.metadata.fileName)
                        "
                      >
                        <Download />
                      </el-icon>
                    </div>
                    <div class="check_box" v-show="downStatus">
                      <el-checkbox :label="childItem.imageId">{{}}</el-checkbox>
                    </div>
                  </li>
                </ul>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <!-- 图片预览框 -->
      <el-image-viewer
        :url-list="currentSrcList"
        :initial-index="initialIndex"
        hide-on-click-modal="true"
        v-if="showImageViewer"
        @close="closeImageViewer"
      />
    </ele-drawer>
    <ele-dialog-video
      v-if="isVisebleVideo"
      :isVisebleVideo="isVisebleVideo"
      :dataUrl="dataUrl"
      @onClose="onClose"
    >
    </ele-dialog-video>
  </div>
</template>
<script>
import { t } from '../../../../languages';
import { defineComponent, ref, toRefs } from "vue";
import { ElMessage, ElNotification } from "element-plus";
import taskManage from "@/network/task";
import download from "@/network/download";
import EleDialogVideo from "@/components/package/ele-dialog-video.vue";

export default defineComponent({
  components:{
    EleDialogVideo
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    picRecordId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const picList = ref([]); //展示列表
    const previewSrcList = ref([]); //预览列表
    const { picRecordId } = toRefs(props); //任务记录id
    const others = ref([]); //值为-1的情况
    const othersList = ref([]);
    getRecordPics();
    /* 获取任务记录图片 */
    async function getRecordPics() {
      const data = await taskManage.getPicList(picRecordId.value);
      console.log(data.resultData);
      for (let liValue of data.resultData) {
        if (liValue.metadata.waypointIndex === -1) {
          if (!others.value[0]) {
            others.value[0] = [];
          }
          others.value[0].push(liValue.imageUrl);
          if (!othersList.value[0]) {
            othersList.value[0] = {
              checked: false,
              checkArr: [],
              itemArr: [],
            };
          }
          othersList.value[0].itemArr.push(liValue);

        } else {
          if (!previewSrcList.value[liValue.metadata.waypointIndex]) {
            previewSrcList.value[liValue.metadata.waypointIndex] = [];
          }
          previewSrcList.value[liValue.metadata.waypointIndex].push(
            liValue.imageUrl
          );
          if (!picList.value[liValue.metadata.waypointIndex]) {
            picList.value[liValue.metadata.waypointIndex] = {
              checked: false,
              checkArr: [],
              itemArr: [],
            };
          }
          picList.value[liValue.metadata.waypointIndex].itemArr.push(liValue);
        }
      }
      picList.value = [...picList.value,...othersList.value]
      previewSrcList.value = [...others.value,...previewSrcList.value]
      for (let i = 0; i < picList.value.length; i++) {
        if (!picList.value[i]) {
          picList.value[i] = {
            checked: false,
            checkArr: [],
            itemArr: [],
          };
        }
      }

      console.log(picList.value);
    }

    /* 单个下载图片 */
    function downloadPic(imgsrc, name) {
      const notification = ElNotification({
        title: t("1"),
        message: t("1008"),
        duration: 0,
        customClass: "notifyStyles",
      });
      fetch(imgsrc, {
        method: "get",
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((data) => {
          notification.close();
          let blob = new Blob([data], { type: "image/jpeg" });
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          notification.close();
          console.error("Error:", error);
        });
    }

    const currentIndex = ref(0);
    // 锚点指向
    function jumpTo(id) {
      currentIndex.value = id;
      document.getElementById(id).scrollIntoView();
    }

    const downStatus = ref(false);
    /* 批量下载 */
    async function batchDownload() {
      let checkedIds = [];
      // 遍历航点
      for (let i = 0; i < picList.value.length; i++) {
        checkedIds = [...checkedIds, ...picList.value[i].checkArr];
      }
      /* 批量下载 */
      if (checkedIds.length <= 0) {
        ElMessage({
          message: t("1009"),
          type: "warning",
        });
      } else {
        const notification = ElNotification({
          title: t("1"),
          message: t("1008"),
          duration: 0,
        });
        await download.downloadSource(
          "/api/v1/tasks/records/images/download",
          {
            ids: checkedIds,
          },
          "application/zip",
          "post"
        );
        ElMessage({
          message: t("1010"),
          type: "success",
        });
        notification.close();
      }
    }

    const initialIndex = ref(0); //点击图片索引
    const showImageViewer = ref(false); //预览框显示与否
    const currentSrcList = ref([]); //当前的航点照片列表
    const isVisebleVideo = ref(false);
    /* 点击图片 */
    function clickImage(index, imgList) {
      currentSrcList.value = imgList;
      initialIndex.value = index;
      showImageViewer.value = true;
    }

    /* 关闭预览框 */
    function closeImageViewer() {
      showImageViewer.value = false;
    }

    const allChecked = ref(false); //总全选绑定变量
    /* 航点全选 */
    function childAllCheck(checked, index) {
      picList.value[index].checked = checked;
      if (checked === true) {
        // 全选
        picList.value[index].checkArr = picList.value[index].itemArr.map(
          (item) => {
            return item.imageId;
          }
        );
        // 遍历航点选中状态--判断总全选状态
        for (let i = 0; i < picList.value.length; i++) {
          if (picList.value[i].checked === false) {
            // 航点非全部选中
            allChecked.value = false;
            return;
          }
        }
        // 航点全部选中
        allChecked.value = true;
      } else {
        // 全不选
        picList.value[index].checkArr = [];
        // 总航点非全选
        allChecked.value = false;
      }
    }

    /* 单个照片复选框选择 */
    function childCheck(checked, index) {
      if (checked.length === picList.value[index].itemArr.length) {
        // 选中数量等于照片数量--该航点全选
        picList.value[index].checked = true;
        // 遍历航点选中状态--判断总全选状态
        for (let i = 0; i < picList.value.length; i++) {
          if (picList.value[i].checked === false) {
            // 航点非全部选中
            allChecked.value = false;
            return;
          }
        }
        // 航点全部选中
        allChecked.value = true;
      } else {
        // 只要不是全选--取消全选
        picList.value[index].checked = false;
        allChecked.value = false;
      }
    }

    /* 总的全选 */
    function parentAllCheck(checked) {
      if (checked === true) {
        // 全选航点全选
        for (let i = 0; i < picList.value.length; i++) {
          picList.value[i].checkArr = [];
          picList.value[i].checkArr = picList.value[i].itemArr.map((item) => {
            return item.imageId;
          });
          picList.value[i].checked = true;
        }
      } else {
        for (let i = 0; i < picList.value.length; i++) {
          picList.value[i].checkArr = [];
          picList.value[i].checked = false;
        }
      }
    }
    const dataUrl = ref('');
    function clickVideo(item) {
      dataUrl.value = item.imageUrl;
      isVisebleVideo.value = true;
    }
    function onClose() {
      isVisebleVideo.value = false;
    }

    return {
      picList,
      previewSrcList,
      others,
      othersList,
      currentIndex,
      downStatus,
      initialIndex,
      showImageViewer,
      currentSrcList,
      allChecked,
      downloadPic,
      jumpTo,
      batchDownload,
      clickImage,
      closeImageViewer,
      childAllCheck,
      childCheck,
      parentAllCheck,
      onClose,
      isVisebleVideo,
      clickVideo,
      dataUrl,
    };
  },
});
</script>

<style scoped lang="less">
.pic_detail {
  /deep/ .el-drawer__body {
    padding: 0;
  }
}
.main_box {
  position: relative;
  height: 100%;
  padding-top: 70px;
  overflow: hidden;
  .main_box_hd {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    line-height: 70px;
    padding-left: 27px;
    background: rgba(0, 112, 195, 0.4);
    color: #36e5ff;
    font-size: 20px;
    .title {
      display: inline-block;
      height: inherit;
    }
    .sort_box {
      display: inline-block;
      height: inherit;
      width: 862px;
      border-right: 1px solid #1b8198;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
  .main_box_bd {
    height: 100%;
    overflow: auto;
    .el-checkbox-group {
      font-size: 14px;
    }
  }
}
.item {
  padding: 33px 26px 0;
  .item_hd {
    padding-bottom: 25px;
    .sort_a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin: 0;
      background: #1b8198;
    }
    .all_check_box {
      height: 40px;
      line-height: 40px;
    }
  }
}
.item:not(:last-child) {
  border-bottom: 1px solid #36e5ff;
}
a {
  text-decoration: none;
  color: inherit;
}
.sort_a {
  display: inline-block;
  width: 47px;
  height: 47px;
  line-height: 47px;
  margin: 0 8px;
  text-align: center;
  background: transparent;
  border-radius: 50%;
  vertical-align: middle;
}
.sort_a:hover,
.curretn_sort_a {
  background: #1b8198;
}
.pic_ul {
  li {
    position: relative;
    width: 21.25%;
    height: 200px;
    margin-right: 5%;
    margin-bottom: 30px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    .pic_con {
      height: 150px;
      /deep/ .el-image {
        width: 100%;
        height: 100%;
      }
      .my-video{
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;
      }
    }
    .pic_name {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      line-height: 50px;
      padding-left: 10px;
      padding-right: 35px;
      background: #001627;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .name_con {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .element_icon {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .element_icon:hover {
        color: #0090da;
      }
    }
    .check_box {
      position: absolute;
      top: 18px;
      left: 18px;
    }
  }
  li:nth-of-type(4n) {
    margin-right: 0;
  }
}
/* 重写复选框为单选框 */
.check_box {
  .check_label {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 1em;
    color: #36e5ff;
  }
  /deep/ .el-checkbox {
    height: auto;
    vertical-align: middle;
    .el-checkbox__input {
      .el-checkbox__inner {
        width: 25px;
        height: 25px;
        background: transparent !important;
        border-color: #fff;
        border-width: 1px;
        border-radius: 50%;
      }
      .el-checkbox__inner::after {
        display: none;
        position: absolute;
        top: 5px;
        left: 5px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #0090da;
        transform: none;
        border: 0;
        transition: display 0.15s;
      }
    }
    .el-checkbox__input.is-checked {
      .el-checkbox__inner {
        box-shadow: 0px 0px 3px 1px #0090da;

        border-color: #0090da;
      }
      .el-checkbox__inner::after {
        display: block;
      }
    }
  }
}
.btn_group {
  padding-right: 29px;
  .bg_btn {
    background: rgba(0, 112, 195, 0.4);
    border-color: #1b8198;
    color: #fff;
  }
  .no_bg_btn {
    background: transparent;
    border-color: #36e5ff;
    color: #36e5ff;
  }
  .left_35 {
    margin-left: 35px;
  }
  .check_box {
    display: inline-block;
  }
  .bg_btn:hover,
  .no_bg_btn:hover {
    opacity: 0.8;
  }
}
</style>